export const getPageDisplayName = (pageName) => {
  // 根据路由参数或其他逻辑生成动态的显示名称
  // 这里可以根据您的需求来决定显示名称的逻辑
  switch (pageName) {
    case "generateJson":
      return "Json数据生成器";
    case "phonePrice":
      return "手机号码吉凶测试";
    case "qrcode":
      return "二维码生成器";
    case "textReplace":
      return "文本替换";
    case "simplifiedToTraditional":
      return "中文简繁体转换";
    case "ChatGPT":
      return "ChatGPT";
    default:
      return "未知";
  }
};
