import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import { getPageDisplayName } from "../utils/routerName";
Vue.use(VueRouter);

const routes = [
  { path: "/", redirect: "/home" },
  {
    path: "/home",
    name: "home",
    redirect: "/home/homeList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Home/home.vue"),
    meta: {
      displayName: "首页",
      pathName: "/home",
    },
    children: [
      {
        path: "/hotResource",
        name: "hotResource",
        component: () => import("../views/Main/repleatMain/repleatMain.vue"),
      },
      {
        path: "/home/homeList",
        name: "homeList",
        meta: {
          displayName: "小工具",
          pathName: "/home/homeList",
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */ "../views/Home/homeComponents/List.vue"
          ),
      },
      {
        path: "/home/:pageName",
        component: () => import("../components/pageRender.vue"),
        props: true,
        meta: {
          displayName: "", // 这里先留空，后面会动态设置
          pathName: "",
        },
        beforeEnter: (to, from, next) => {
          // 在路由导航守卫中动态设置displayName属性
          const dynamicDisplayName = getPageDisplayName(to.params.pageName); // 从路由参数中获取动态显示名称的逻辑
          to.meta.displayName = dynamicDisplayName; // 设置动态的显示名称
          to.meta.pathName = "/home/" + to.params.pageName;
          next();
        },
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
export default router;
