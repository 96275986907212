import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/font/font.css";
import mavonEditor from "mavon-editor";
Vue.use(mavonEditor);
import "./style/font/iconfont.css";
import "./style/font/iconfont.js";
import "mavon-editor/dist/css/index.css";
import "normalize.css/normalize.css";
import "element-ui/lib/theme-chalk/base.css";
// import "./element-variables.css";
// markdown  开始
import VMdEditor from "@kangc/v-md-editor";
import "@kangc/v-md-editor/lib/style/base-editor.css";
import githubTheme from "@kangc/v-md-editor/lib/theme/github.js";
import "@kangc/v-md-editor/lib/theme/style/github.css";

// highlightjs
import hljs from "highlight.js";
// import ElementUI from 'element-ui'
import "./styles.scss";

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

Vue.use(VMdEditor);
// 动画效果
import "animate.css";
// markdown 结束
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
